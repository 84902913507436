// extracted by mini-css-extract-plugin
export var root = "sample-module--root--f9311";
export var blogContainer = "sample-module--blogContainer--94b7d";
export var excerpt = "sample-module--excerpt--53842";
export var footerContainer = "sample-module--footerContainer--cd3cf";
export var socialMediaListContainer = "sample-module--socialMediaListContainer--4fd31";
export var blogParagraph = "sample-module--blogParagraph--54e81";
export var imagesContainer = "sample-module--imagesContainer--f1361";
export var imageContainer = "sample-module--imageContainer--78ea7";
export var blogSubHeader = "sample-module--blogSubHeader--e3d3b";
export var content = "sample-module--content--ec8b1";