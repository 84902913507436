import React from 'react';
import { navigate } from 'gatsby';
import * as styles from './sample.module.css';

import Blog from '../../components/Blog';
import Container from '../../components/Container';
import Layout from '../../components/Layout/Layout';
import Icon from '../../components/Icons/Icon';
import Button from '../../components/Button';

const SamplePage = (props) => {
  return (
    <Layout>
      <div className={styles.root}>
        <Container>
          <div className={styles.blogContainer}>
            <Blog
              category={'Anime'}
              title={'NCHammer23'}
              image={'/blogs/NCHammer23.jpg'}
              alt={''}
            >
              <div className={styles.content}>
                <p className={styles.excerpt}>
                NCHammer23 is YouTuber who has gained a following
                for his engaging content of anime comentary and theories.
                With over 400'000 subscribers and more daily, his channel
                offers videos about all the most popular anime such as Naruto,
                One Piece, Attack on Titan and more.
                </p>
              </div>
              <div className={styles.imagesContainer}>
                  <img src={'/blogs/NCHammer23_thumbnail.png'} alt={'picture'} />
              </div>
              <div className={styles.content}>
                <h2 className={styles.blogSubHeader}>ENG</h2>
                <p className={styles.blogParagraph}>
                  His most popular videos are related to Naruto content and due to his
                  interest in various other massively popular anime his Naruto content was joined with
                  theory and explanation videos about various other anime. 
                  Creation of anime related videos spread to creation of other anime related Youtube channels such as 
                  <a href="https://www.youtube.com/channel/UCjN_ENRjrCtBHWqBH3voOUA">The Weeb Commander</a> for theories less related to Naruto, 
                  <a href="https://www.youtube.com/channel/UCeRJGDZxr9I4h-TsNYuq56w">Hammer's Collection</a> for anime collectibles and figures,
                   and participation in <a href="https://www.youtube.com/@otakusanonymous">Otakus anonymous</a> podcast about relevant anime news.
                </p>
                <p className={styles.blogParagraph}>
                  As for artistic side, his videos are filmed in format to create a feeling as of friend is explaining You some anime related
                  ideas in a video call from sitting at his PC, which makes it relatable and more inclusive than for example more professional studio
                  setups might. 
                </p>
                <p className={styles.blogParagraph}>
                 A lot of ideas and theories come from the channel fanbase and creates a nice cooperation loop and fun coworking inside channel.
                </p>
                <p className={styles.blogParagraph}>
                  See his content on YouTube: <a href="https://www.youtube.com/@NCHammer23">NCHammer23</a>
                </p>
              </div>
              <div className={styles.content}>
                <br></br>
                <h2 className={styles.blogSubHeader}>LV</h2>
                <p className={styles.blogParagraph}>
                NCHammer23 ir YouTube lietotājs, kurš ir ieguvis sekotāju skaitu
                 par viņa saistošo anime komentāru un teoriju saturu.
                 Viņa kanālam katru dienu ir vairāk nekā 400'000 abonentu un vairāk
                 piedāvā video par visām populārākajām anime, piemēram, Naruto,
                 One Piece, Attack on Titan un citas.
                </p>
                <p className={styles.blogParagraph}>
                Viņa populārākie videoklipi ir saistīti ar Naruto saturu un viņa dēļ
                   interese par dažādām citām ļoti populārām anime, kurai tika pievienots viņa Naruto saturs
                   teorijas un skaidrojumu video par dažādām citām anime.
                </p>
                <p className={styles.blogParagraph}>
                Kas attiecas uz māksliniecisko pusi, viņa videoklipi ir filmēti tādā formātā, lai radītu sajūtu, ka draugs jums izskaidro kādu ar anime saistītu
                   idejas videozvanā, sēžot pie datora, kas padara to salīdzināmu un iekļaujošāku nekā, piemēram, profesionālāku studiju
                   uzstādījumi varētu.
                </p>
                <p className={styles.blogParagraph}>
                Daudz ideju un teoriju nāk no kanāla fanu bāzes, un tas rada jauku sadarbības cilpu un jautru kopstrādni kanālā.
                </p>
                <p className={styles.blogParagraph}>
                Skatiet viņa saturu pakalpojumā YouTube: <a href="https://www.youtube.com/@NCHammer23">NCHammer23</a>
                </p>
              </div>
            </Blog>
          </div>
          <div className={styles.footerContainer}>
        <span>Find his content on:</span>
        <div className={styles.socialMediaListContainer}>
          <div className={styles.socialMediaIconContainer}>
            <Icon symbol={'twitterinverse'}></Icon>
          </div>
          <div className={styles.socialMediaIconContainer}>
            <Icon symbol={'facebookinverse'}></Icon>
          </div>
         {/*<div className={styles.socialMediaIconContainer}>
            <Icon symbol={'pinterestinverse'}></Icon>
</div> */}
          <div className={styles.socialMediaIconContainer}>
            <Icon symbol={'youtube'}></Icon>
          </div>
        </div>
        <Button onClick={() => navigate('/')} level={'secondary'}>
          back to summaries
        </Button>
      </div>
        </Container>
      </div>
    </Layout>
  );
};

export default SamplePage;
